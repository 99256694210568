import React, { useRef, useLayoutEffect, useCallback, ReactNode, useEffect } from 'react'
import { PanelContextProvider } from 'refactor/hooks/usePanelContext'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import styles from 'components/panels/panel-container.module.scss'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { SMALL, BIG, transformTo, getTransform } from 'lib/transform'
import useStore from 'state/knovStore'

interface PanelContainerProps {
    panel: any // Specify a more accurate type if possible
    index: number
    hide: boolean
    children: ReactNode
    isEmpty: boolean
    animationPanel: boolean
    animationStyles: {
        mirrorPanelRef: HTMLDivElement
        transform: string
        scrollTop: number
    }
}

export default React.memo(function PanelContainer({
    panel,
    index,
    hide,
    children,
    isEmpty,
    animationPanel,
    animationStyles,
}: PanelContainerProps) {
    //console.log('RENDER PANEL CONTAINER', index, panel.panelId, panel.filter)
    useStore(state => state.activeIndex)
    useStore(state => state.panels.state.length)
    const panelContainerRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(function initAnimationPanelTransform() {
        if (!isMobile && animationPanel && animationStyles && panelContainerRef.current) {
            const transformContainer =
                panelContainerRef.current?.querySelector('.transform-container')
            const scrollContainer = panelContainerRef.current?.querySelector('.scroll-container')

            //console.log('animationStyles', animationStyles)
            requestAnimationFrame(() => {
                // We have to requestAnimationFrame for the scrolltop to be set correctly.
                if (transformContainer)
                    transformContainer.style.transform = animationStyles.transform
                if (scrollContainer) scrollContainer.scrollTop = animationStyles.scrollTop
            })
        }
    }, [])

    // Transform scenarios.
    // User swipes, this is handled in PanelController.
    // Inserting a panel, handled below.
    // Removing a panel, handled below.
    // During a swipe, we dont need to run the below.

    useLayoutEffect(function performTransform() {
        if (isMobile || animationPanel || !panelContainerRef.current) return

        const transformContainer = panelContainerRef.current?.querySelector('.transform-container')
        const scrollContainer = panelContainerRef.current?.querySelector('.scroll-container')
        //const panelComp = panelContainerRef.current?.querySelector('.panel-comp')
        if (!transformContainer || !scrollContainer) return

        const centerIndex = getCenterIndex()
        //const matrix = new DOMMatrix(getComputedStyle(transformContainer).transform)
        //const currentScale = matrix.a
        // This is already scaled to the transform scale if transform: scale translate.
        //const currentTranslateY = matrix.f

        // We use the css values instead of the matrix bc these update instantly, while the matrix has to wait for a repaint, and bc parts of the code are async we want to snapshot the desired values before any callbacks run.
        const transformObject = getTransform(transformContainer.style.transform)
        const currentScale = transformObject.scale || 1

        //console.log(
        //    'performTransform',
        //    panel.panelId,
        //    index,
        //    centerIndex,
        //    currentScale,
        //    panel.filter,
        //)

        if (centerIndex === index && currentScale === SMALL) {
            transformTo(BIG, panel)
        } else if (centerIndex !== index && currentScale === BIG) {
            transformTo(SMALL, panel)
        }
    }, [])

    const onPanelScroll = useCallback((ev: React.UIEvent<HTMLDivElement>) => {
        if (window.isPanelScrolling) clearTimeout(window.isPanelScrolling)
        window.isPanelScrolling = setTimeout(() => {
            window.isPanelScrolling = null
        }, 100)
    }, [])

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    // We  memoize the panel context so it does not cause re-renders when passed to the provider below.
    const panelContext = React.useMemo(
        () => ({
            panel,
            panelId: panel.panelId,
            filter: panel.filter,
            hide,
            panelContainerRef,
            scrollContainerRef,
            animationPanel,
            animationStyles,
        }),
        [panel.panelId, panel.filter, hide],
    )

    useEffect(function cleanupAnimationPanelRef() {
        return () => {
            if (animationPanel && panel.setAnimationPanelRef) {
                panel.setAnimationPanelRef(null)
            }
        }
    }, [])

    return (
        <PanelContextProvider value={panelContext}>
            <div
                style={{ visibility: hide ? 'hidden' : 'visible' }}
                className={cn(
                    isMobile && 'is-mobile',
                    styles.panelContainer,
                    'panel-container-comp',
                )}
                ref={useCallback(ref => {
                    //console.log('panel ref empty:', panel.empty,  'animation:', !!animationPanel,panel.panelId, panel.filter, panel)
                    if (!ref) {
                        return
                    }

                    panelContainerRef.current = ref
                    const panelRef = panel.getPanelRef()
                    if (animationPanel) {
                        panel.setAnimationPanelRef(ref)
                    } else if (panelRef !== ref) {
                        panel.setPanelRef(ref)
                        const prevScrollTop = panelRef?.scrollTop
                        if (prevScrollTop) {
                            ref.scrollTop = prevScrollTop
                        }
                    }
                }, [])}
            >
                <div
                    ref={scrollContainerRef}
                    className={cn(
                        'scroll-container', // Deterministic class for dom selection.
                        styles.scrollContainer,
                        'h100',
                    )}
                    onScroll={onPanelScroll}
                >
                    <div
                        className={cn(
                            styles.transformContainer,
                            `panel-${panel.panelId}`,
                            'transform-container h100',
                            // PanelState contains the 'center-panel' class for the initially centered panel.
                            // For easier identification in dom.
                            animationPanel && 'animation-panel',
                            // This is set imperatively via cssAnimations.js as swipes happen, but update declaratively here for animation panels for correct sizing during transitions.
                            //getCenterIndex() === index && 'center-panel',
                            animationPanel && getCenterIndex() === index && 'center-panel',
                            isMobile && 'is-mobile',
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </PanelContextProvider>
    )
})
