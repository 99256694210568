import React from 'react'
import UserIcon from 'components/users/UserIcon'
import UserName from 'components/users/UserName'
import CommonEditor from 'components/shared/CommonEditor'
import VideoButton from 'components/answers/AnswerControls/VideoButton'
import MobileVideoButton from 'components/answers/AnswerControls/MobileVideoButton'
import RecordScreenButton from 'components/answers/AnswerControls/RecordScreenButton'
import AddImageButton from 'components/answers/AnswerControls/AddImageButton'
import AddFileButton from 'components/answers/AnswerControls/AddFileButton'
import FileEmbed from './FileEmbed'
import Connector from '../state/Connector'
import PostEmbed from './PostEmbed'
import api from '../../api/api'
import { LinkPreview } from 'components/shared/LinkPreview'
import { getText, getImages } from '../../lib/value'
import { isMobile } from 'react-device-detect'
import answerModel from 'components/answers/answerModel'
import styles from './new-answer.module.scss'
import cn from 'classnames'
import * as cache from 'state/cache'
import { fileHandler } from 'components/answers/uploader'
import { debugCopy } from 'utils'
import { isEqual } from 'lodash'
import KnovAgentButtonUIMain from '../quests/KnovAgentButtonUIMain'
import AiImageButton from '../quests/AiMagicWandMenuButton'
import { cacheQuest } from 'state/cache'
import useGetAnswer from 'refactor/hooks/api/useGetAnswer'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import { isPanelLeft, isPanelCenter, isPanelRight } from 'state/imperativeApis/swiperApi'
import usePanelContext from 'refactor/hooks/usePanelContext'
import { defaultModel } from 'components/quests/KnovAgentButtonUIMain'

const stateSelector = (state, props) => ({
    // Override answer from cache.
    //newAnswer: props.newAnswer,
    activeSpaceId: state.activeSpaceId,
    agentQuestIds: state.agentQuestIds,
    questAgentModels: state.questAgentModels,
    set: state.set,
    DEBUG: state.DEBUG,
})

class NewAnswer extends React.PureComponent {
    constructor(props) {
        super(props)

        this.CHAR_LIMIT = 280
        this.ORIG_COLOR = 'rgb(102, 102, 102)'
        this.LIMIT_COLOR = 'red'

        const questId = this.props.quest?.id
        const talkToAgent = this.props.agentQuestIds?.some(qid => qid === questId) || false
        const agentModel = this.props.questAgentModels?.[questId] || 'gpt-4'

        this.state = {
            value: this.props.newAnswer?.delta_json || '',
            talkToAgent,
            agentModel,
        }
    }

    componentDidMount = () => {
        const { panel, animationPanel } = this.props.panelContext
        const panelId = panel.panelId
        const isOnScreen = isMobile
            ? isPanelCenter(panelId)
            : isPanelLeft(panelId) || isPanelCenter(panelId) || isPanelRight(panelId)

        return

        if (!animationPanel && isOnScreen && this.props.active && !isMobile)
            // We need to step outside the rendering cycle otherwise it messes up the panel indexing.
            setTimeout(() => this.richEditor?.current?.focus())
    }

    hasFiles = () => !!this.props.newAnswer?.file_urls?.length
    hasVideo = () => !!this.props.newAnswer?.recording_url
    hasImage = deltaJson => !!this.props.newAnswer?.answer_image_url || getImages(deltaJson)

    getNewAnswer = () => {
        const now = new Date()
        const content = this.getContent()
        const deltaJson = this.getDeltaJson()

        return {
            ...this.props.newAnswer,
            is_draft: false,
            space_id: this.props.activeSpaceId,
            content,
            delta_json: deltaJson,
            created_at: now,
            updated_at: now,
            position: new Date().getTime(),
            isLocal: true,
        }
    }

    postAnswer = async (skipValidation = false) => {
        if (!this.ensureCurrentUser()) return

        const quest = this.props.quest
        // TODO is this getting the local child_quest? for selected answers?
        const newAnswer = {
            ...this.getNewAnswer(),
            talk_to_agent: this.state.talkToAgent,
            ...(this.state.talkToAgent ? { agent_model: this.state.agentModel } : {}),
        }

        if (answerModel.hasContent(newAnswer) || skipValidation) {
            // Dont clear the editor if title to prevent flicker. Title will re-render another new answer comp.
            if (!this.props.isTitle && this.richEditor.current) {
                const editor = this.richEditor.current.getEditor()
                editor.setText('')
                this.setEditorValue('')
                this.setState({
                    value: '',
                })
            }

            let draftQuest
            if (quest.is_draft) {
                draftQuest = {
                    ...quest,
                    is_draft: false, // This publishes the quest in the panel and make react-query update from server on next useGetQuest().
                    created_at: newAnswer.created_at,
                    updated_at: newAnswer.updated_at,
                    parent: newAnswer,
                    talk_to_agent: this.state.talkToAgent,
                    agent_model: this.state.agentModel,
                    new_answer: null,
                    new_answer_id: null,
                }
                cacheQuest(draftQuest)
                this.props.focusNewAnswer()
                history.pushState({}, null, quest.path)

                // TODO useMutate?
                const apiQuest = await api.createQuest(draftQuest)
                logEv('POST_ROOT')
                // We have to do this one here bc we can't rely on onMount in QuestContainer since we mount a local is_draft queest and it doesn't exist yet on the backend so the view action call will 404.
                apiQuest && (await api.userViewsQuest(apiQuest.id))
                const actionsQueryKey = ['actions']
                queryClient.invalidateQueries({ queryKey: actionsQueryKey })
                queryClient.refetchQueries({ queryKey: actionsQueryKey }, { exact: true })
            } else {
                draftQuest = {
                    ...quest,
                    updated_at: newAnswer.updated_at,
                    sorted_answers: [...(quest.sorted_answers || []), newAnswer],
                    new_answer: null,
                    new_answer_id: null,
                }
                // Make sure newQuest is cached first bc it has newAnswers with child_quests.
                // newAnswer.child_quests is needed for instant local branching until server roundtrip.
                cacheQuest(draftQuest)
                // addAnswer handles quest answer collapse.
                this.props.addAnswer(newAnswer)

                if (newAnswer?.id) {
                    api.createAnswer(newAnswer)
                    logEv('POST_ANSWER')
                }
            }
        } else {
            return null
        }
    }

    getEditor = () => this.richEditor.current?.getEditor()
    getDeltaJson = () => this.getEditor()?.getContents()
    getContent = () => getText(this.getDeltaJson() || {}).trim()

    ensureCurrentUser = () => {
        if (gon.currentUser) {
            return true
        } else {
            alert('Please login first!')
            // $('#auth-modal').modal('show')
            return false
        }
    }

    processMentions = async delta => {
        const mentions = delta.ops.reduce(
            (acc, cur) => (cur.insert?.mention?.value ? [...acc, cur.insert.mention?.value] : acc),
            [],
        )

        const teamId = this?.props?.quest?.team?.id
        let team
        if (teamId) team = await api.getTeam(teamId)

        if (team) {
            const toAdd = []
            mentions.forEach(mentionedName => {
                if (!team?.users?.find(u => u?.name === mentionedName)) toAdd.push(mentionedName)
            })

            if (toAdd?.length > 0) {
                let msg =
                    toAdd?.length == 1
                        ? `@${toAdd[0]} is not part of stream '${team?.name}'. Would you like to add them?\n`
                        : `The following users aren't part of stream '${
                              team?.name
                          }'. Would you like to add them?\n\n${toAdd.map(u => `- @${u}`).join('\n')}
                        `

                msg += "\nPress 'OK' to add, or 'Cancel' to message only."

                const inviteUsers = confirm(msg)
                if (inviteUsers) api.addToTeam({ names: toAdd }, teamId)
            }
        }

        return mentions
    }

    onPost = () => {
        if (!this.ensureCurrentUser()) return

        const content = this.getContent()
        const deltaJson = this.getDeltaJson()
        // TODO this errors out if user is not a stream admin, need to fix.
        //this.processMentions(deltaJson)
        this.postAnswer(content, deltaJson)
        this.setEditorValue('')
        this.setState({
            linkPreviews: [],
        })
    }

    postOnVideo = async videoType => {
        let content, deltaJson
        if (this.richEditor.current) {
            content = this.getContent().trim()
            deltaJson = this.getDeltaJson()
        }
        if (!content) {
            content = `!${videoType}`
            deltaJson = { ops: [{ insert: content, attributes: { bold: true } }] }
        }
    }

    captureSetValue = setValue => {
        // We want to set value of Common Editor when post button is pressed so need to grab it from inside the functional comp.
        this.setEditorValue = setValue
    }

    onChange = (value, text) => {
        if (!gon.currentUser) {
            $('#auth-modal').modal('show')
            return
        }
    }

    tmpAnswerId = () => `tmp-id-new-answer-for-quest-${this.props.quest.id}`

    richEditor = React.createRef()

    focusEditor = () => {
        if (this.richEditor.current) {
            setTimeout(() => this.richEditor?.current?.focus())
        }
    }

    onMouseEnter = () => {
        this.setState({ hover: true })
    }

    onMouseLeave = () => {
        this.setState({ hover: false })
    }

    imgInputRef = React.createRef()
    imgPreviewRef = React.createRef()
    videoInputRef = React.createRef()
    videoPreviewRef = React.createRef()
    fileInputRef = React.createRef()

    fileHandler = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const files = ev.target.files
        fileHandler(files?.[0], this.props.newAnswer.id)
    }

    videoHandler = video => {
        if (video) fileHandler(video, this.props.newAnswer.id)
    }

    removeFile = () => {
        if (this.fileInputRef && this.fileInputRef.current) this.fileInputRef.current.value = ''
        cache.updateCachedAnswer(this.props.newAnswer?.id, { files: [], file_urls: [] })
    }

    onClickMobileVideo = ev => {
        ev.stopPropagation()

        if (!this.ensureCurrentUser()) {
            ev.preventDefault()
            return
        }
    }

    setEmbeds = embeds => {
        const embedIds = (embeds || []).map(e => e.id)
        const currentEmbedIds = (this.props.newAnswer?.embeds || []).map(e => e.id)
        if (!isEqual(currentEmbedIds, embedIds)) {
            cache.updateCachedAnswer(this.props.newAnswer?.id, { embeds })
        }
    }

    setLinkPreviews = linkPreviews => this.setState({ linkPreviews: linkPreviews || [] })

    render() {
        const debug = this.props.DEBUG
        const quest = this.props.quest
        const newAnswer = this.props.newAnswer
        // TODO need to handle postAnswer with content param when clicking button!!!! So need access to editor value.
        const hoverClass = this.state.hover ? 'is-hover' : ''
        const mobileClass = isMobile ? 'is-mobile' : ''

        const content = this.getContent()
        const deltaJson = this.getDeltaJson()
        const embeds = newAnswer?.embeds || []

        const validStyles = answerModel.hasContent(this.getNewAnswer()) ? styles.validBtn : null

        const charCount = this.getContent()?.length
        const imgUrl = newAnswer?.answer_image_url
        const videoUrl = newAnswer?.recording_url

        const payloadContainerStyles = styles.payloadContainer

        const linkPreviews = this.state.linkPreviews || []

        const titleClass = this.props.isTitle ? 'new-title' : ''
        const titleEditorContainerStyles = this.props.isTitle ? styles.titleEditorContainer : null

        const userIconStyles = !this.props.isTitle ? styles.userIcon : null
        const fileUrl = newAnswer?.file_urls?.[0]

        return (
            <div
                className={cn(
                    styles.newAnswerComp,
                    `new-answer-comp ${hoverClass} ${mobileClass}`,
                    titleClass,
                )}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {debug && (
                    <div
                        style={{
                            background: '#eaf0ff',
                            marginTop: -5,
                            marginBottom: 15,
                            marginLeft: 50,
                        }}
                    >
                        <strong style={{ marginLeft: 5 }}>Q:</strong>
                        <span onClick={() => debugCopy(quest?.id)}>{quest?.id}</span>
                        <strong style={{ marginLeft: 10 }}>D:</strong>
                        <span onClick={() => debugCopy(newAnswer?.id)}>{newAnswer?.id}</span>
                    </div>
                )}

                <div className={styles.questHeader}>
                    <div className={styles.userContainer}>
                        <div className={cn(styles.userIconContainer)}>
                            <UserIcon
                                user={gon.currentUser}
                                showKnovigator={false}
                                contextStyles={userIconStyles}
                            />
                        </div>

                        <div className={cn(styles.userNameContainer)}>
                            <UserName user={gon.currentUser} answerCreatedAt={'now'} column />
                        </div>
                    </div>
                </div>

                <div className={styles.userPostContainer}>
                    {/*this.props.showUser && gon.currentUser && !this.props.blog && (
                        <div className={cn(styles.userIconContainer)}>
                            <UserIcon 
                                user={gon.currentUser}
                                contextStyles={styles.userIcon}
                            />
                        </div>
                    )*/}

                    <div className={cn(styles.editorContainer, titleEditorContainerStyles)}>
                        <CommonEditor
                            ref={this.richEditor}
                            type={'new-answer'}
                            quest={quest}
                            /*
                            TODO we make CommonEditor keep local state instead of zustand bc were running into rendering perf issues earlier.
                            However, we were bleeding state to all other answers, and fixed this, so need to test if we can just pass zustand state instead of local state for a more straightforward and safer implementation.
                            */
                            value={this.state.value}
                            placeholder={this.props.placeholder || 'Post new message...'}
                            setEmbeds={this.setEmbeds}
                            setLinkPreviews={this.setLinkPreviews}
                            imgHandler={this.fileHandler}
                            postHandler={this.onPost}
                            onChange={this.onChange}
                            captureSetValue={this.captureSetValue}
                            active={this.props.active}
                        />

                        {linkPreviews &&
                            linkPreviews.map((href, i) => (
                                <div key={i} style={{ marginTop: '15px' }}>
                                    <LinkPreview url={href} />
                                </div>
                            ))}
                    </div>
                </div>

                {!!embeds?.length && (
                    <div className={styles.embedsContainer}>
                        {embeds.map((embed, idx) => {
                            return (
                                <div key={idx}>
                                    <PostEmbed
                                        label="QUOTE"
                                        isQuote={true}
                                        panel={this.props.panel}
                                        parentAnswer={this.props.newAnswer}
                                        containerAnswer={this.props.newAnswer}
                                        answer={embed}
                                        containerQuest={this.props.quest}
                                        delete={this.deleteEmbed}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )}

                {fileUrl && (
                    <div
                        key={`new-answer-files-${this.props.quest.id}`}
                        className={styles.filesContainer}
                    >
                        <FileEmbed
                            file={fileUrl}
                            canDelete={gon.currentUser?.id === this.props.quest.user_id}
                            showDelete={true}
                            removeFile={this.removeFile}
                        />
                    </div>
                )}

                {videoUrl && (
                    <div className={cn(styles.videoContainer)} onClick={ev => ev.stopPropagation()}>
                        <video ref={this.videoPreviewRef} controls playsInline src={videoUrl} />
                    </div>
                )}

                {imgUrl && (
                    <div className={payloadContainerStyles}>
                        <img src={imgUrl} />
                    </div>
                )}

                <div className={cn(styles.btnContainer)}>
                    <div className={styles.participantContainer}></div>

                    <div className={styles.buttons}>
                        <div className={styles.newAnswerBtnContainer}>
                            <AddFileButton
                                btnId={`${this.props.quest.id}-${this.props.panel}`}
                                ref={this.fileInputRef}
                                fileHandler={this.fileHandler}
                            />
                        </div>

                        {isMobile && (
                            <div className={styles.newAnswerBtnContainer}>
                                <AddImageButton
                                    btnId={`${this.props.quest.id}-${this.props.panel}`}
                                    ref={this.imgInputRef}
                                    imgHandler={this.fileHandler}
                                />
                            </div>
                        )}

                        <div className={styles.newAnswerBtnContainer}>
                            {isMobile ? (
                                <MobileVideoButton
                                    ref={this.videoInputRef}
                                    btnId={`video-input-new-answer-for-quest-${this.props.quest.id}-${this.props.panel}`}
                                    quest={this.props.quest}
                                    onClick={this.onClickMobileVideo}
                                    videoHandler={this.fileHandler}
                                />
                            ) : (
                                <VideoButton
                                    btnId={`video-input-new-answer-for-quest-${this.props.quest.id}-${this.props.panel}`}
                                    quest={this.props.quest}
                                    videoHandler={this.videoHandler}
                                />
                            )}
                        </div>

                        {!isMobile && (
                            <div className={styles.newAnswerBtnContainer}>
                                <RecordScreenButton
                                    quest={this.props.quest}
                                    videoHandler={this.videoHandler}
                                />
                            </div>
                        )}

                        <div className={styles.agentButtonContainer}>
                            <AiImageButton
                                contextStyles={styles.knovAgent}
                                imageStyles={styles.knovAgentImage}
                                getEditor={this.getEditor}
                            />
                        </div>

                        <div className={styles.agentButtonContainer}>
                            <KnovAgentButtonUIMain
                                contextStyles={styles.knovAgent}
                                imageStyles={styles.knovAgentImage}
                                activeModel={this.props.questAgentModels?.[quest?.id]}
                                setModel={model => {
                                    const questId = quest?.id

                                    this.setState(
                                        state => ({
                                            talkToAgent: true,
                                            agentModel: model,
                                        }),
                                        () => {
                                            const newQuestAgentModels = {
                                                ...this.props.questAgentModels,
                                                [questId]: model,
                                            }

                                            let agentQuestIds = this.props.agentQuestIds || []
                                            const newAgentQuestIds = [...agentQuestIds, questId]

                                            api.updateUserSpaceOptions({
                                                quest_agent_models: newQuestAgentModels,
                                                agent_quest_ids: newAgentQuestIds,
                                            })

                                            this.props.set({
                                                questAgentModels: newQuestAgentModels,
                                                agentQuestIds: newAgentQuestIds,
                                            })
                                        },
                                    )
                                }}
                                onClick={() => {
                                    let agentQuestIds = this.props.agentQuestIds || []
                                    let newAgentQuestIds
                                    const questId = this.props.quest?.id

                                    if (this.state.talkToAgent)
                                        newAgentQuestIds = agentQuestIds.filter(
                                            qid => qid !== questId,
                                        )
                                    else newAgentQuestIds = [...agentQuestIds, questId]

                                    api.updateUserSpaceOptions({
                                        agent_quest_ids: newAgentQuestIds,
                                    })

                                    this.setState(
                                        state => ({
                                            talkToAgent: !state.talkToAgent,
                                            agentModel: defaultModel,
                                        }),
                                        () => {
                                            this.props.set({ agentQuestIds: newAgentQuestIds })
                                        },
                                    )
                                }}
                                active={this.state.talkToAgent}
                            />
                        </div>

                        <div
                            id="post-btn"
                            className={cn(styles.postAnswerBtn, validStyles)}
                            onClick={this.onPost}
                        >
                            <div className={styles.label}>Post</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Connector(stateSelector, props => ({
    newAnswer: useGetAnswer(props.newAnswer?.id, { cacheOnly: true }),
    panelContext: usePanelContext(),
}))(
    React.forwardRef((props, ref) => (
        <ErrorBoundary label={`NewAnswer ${props.answer?.id}`}>
            <NewAnswer ref={ref} {...props} />
        </ErrorBoundary>
    )),
)
