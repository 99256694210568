import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import questModel from 'components/quests/questModel'
import useQuestChannel from 'state/channels/useQuestChannel'
import usePanelContext from 'refactor/hooks/usePanelContext'
import cn from 'classnames'
import styles from 'components/panels/panel.module.scss'

const QuestInView = ({ children, questId }) => {
    const { panel, animationPanel, animationStyles } = usePanelContext()

    let minHeight = 0
    if (animationPanel && animationStyles) {
        minHeight =
            animationStyles.mirrorPanelRef.querySelector('.quest-in-view-' + questId)
                ?.clientHeight || 0
    }

    const [height, setHeight] = useState(minHeight)

    const { subscribe, unsubscribe } = useQuestChannel(questId)

    const scrollContainer = panel.getPanelRef()?.querySelector('.scroll-container') || document

    const { ref, inView, entry } = useInView({
        root: scrollContainer,
        rootMargin: '1000px 0px 1000px 0px',
        triggerOnce: true,
        onChange: (inView, entry) => {
            if (inView) {
                subscribe()
                questModel.getChildQuests(questId, { uncached: true, includeParent: true })
                if (animationPanel) {
                    setShowBackground(false)
                }
            } else {
                if (!animationPanel && entry.target.clientHeight > height) {
                    //console.log('setHeight?', entry.target.clientHeight, height)
                    setHeight(entry.target.clientHeight)
                }
                unsubscribe()
                setShowBackground(true)
            }
        },
    })

    const [showBackground, setShowBackground] = useState(animationPanel && !inView)

    return (
        <div
            style={{
                minHeight: height,
                ...(showBackground && {
                    background: 'white',
                    borderRadius: 10,
                    //border: 'solid 1px #ddd',
                    //boxShadow: '0 0 3px 0px rgba(0, 0, 0, 0.1)',
                }),
            }}
            ref={ref}
            className={cn(styles.questInView, `quest-in-view-${questId}`)}
        >
            {children}
        </div>
    )
}

export default QuestInView
