import React from 'react'
import styles from './perms.module.scss'
import TeamIcon from 'components/users/TeamIcon'
import PublicIcon from 'components/shared/icons/PublicIcon'
import PrivateIcon from 'components/shared/icons/PrivateIcon'
import LinkIcon from 'components/shared/icons/LinkIcon'
import HodlockerIcon from 'components/shared/icons/HodlockerIcon'
import TreechatIcon from 'components/shared/icons/TreechatIconPng'
import TwetchIcon from 'components/shared/icons/TwetchIcon'
import cn from 'classnames'
import perms from 'lib/perms'
import WithTooltip from 'components/shared/WithTooltip'
import questModel from 'components/quests/questModel'
import useInsertRight from 'refactor/hooks/useInsertRight'

export default function Perms(props) {
    const quest = props.quest
    const amAdmin = perms.amQuestOwner(quest)
    const isBsv = props.isBsv

    const filter = questModel.getFilter(quest)

    // TODO confusing... questModel.isPublic means public:true and not in a team. should be renamed isTreechat
    const isTreechat = questModel.isPublic(quest)
    const isHodlocker = gon.HODLOCKER_TEAM_ID && gon.HODLOCKER_TEAM_ID === quest.team_id
    const isTwetch = gon.TWETCH_TEAM_ID && gon.TWETCH_TEAM_ID === quest.team_id
    const isPublic = isHodlocker || isTreechat || isTwetch

    const isRoot = props.isRoot
    const showTreechat = !isRoot && isTreechat
    const showHodlocker = !isRoot && isHodlocker
    const showTwetch = !isRoot && isTwetch
    const showPublic = props.isRoot && isPublic
    const showTeam = questModel.hasTeam(quest) && !isPublic

    let permsName
    if (showTeam) permsName = quest.team.name
    else if (questModel.isPrivate(quest)) permsName = 'Home'
    else if (showTreechat) permsName = 'Treechat'
    else if (showHodlocker) permsName = 'Hodlocker'
    else if (showTwetch) permsName = 'Twetch'
    else if (showPublic) permsName = 'Public'
    else if (questModel.isLinkShare(quest)) permsName = 'Link Access'

    const { insertRight } = useInsertRight()

    const onClickStream = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (isRoot && isPublic) insertRight({ filter: { public: true } })
        else if (isTreechat) insertRight({ filter: { treechat: true } })
        else insertRight({ filter })
    }

    const onClickControl = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if ((!isBsv || quest.is_draft) && props.setShowPermsOptions)
            props.setShowPermsOptions(!props.showPermsOptions)
    }

    return (
        <div className={styles.permsComp}>
            <WithTooltip tip={`Open ${permsName} stream`}>
                <div className={styles.permsValue} onClick={onClickStream}>
                    <div
                        className={styles.permsIconContainer}
                        //onClick={onClickStream}
                    >
                        {questModel.isPrivate(quest) && (
                            <PrivateIcon contextStyles={cn(styles.permsIcon, styles.privateIcon)} />
                        )}

                        {showTreechat && (
                            <TreechatIcon
                                contextStyles={cn(styles.permsIcon, styles.treechatIcon)}
                            />
                        )}

                        {showHodlocker && (
                            <HodlockerIcon
                                contextStyles={cn(styles.permsIcon, styles.hodlockerIcon)}
                            />
                        )}

                        {showTwetch && (
                            <TwetchIcon contextStyles={cn(styles.permsIcon, styles.twetchIcon)} />
                        )}

                        {showPublic && (
                            <PublicIcon contextStyles={cn(styles.permsIcon, styles.publicIcon)} />
                        )}

                        {questModel.isLinkShare(quest) && (
                            <LinkIcon contextStyles={cn(styles.permsIcon, styles.linkIcon)} />
                        )}

                        {showTeam && (
                            <TeamIcon
                                team={quest.team}
                                contextStyles={cn(styles.permsIcon, styles.initialStyles)}
                                quest={quest}
                            />
                        )}
                    </div>
                </div>
            </WithTooltip>

            {amAdmin && (!isBsv || quest.is_draft) ? (
                <WithTooltip tip={'Choose stream to post in'} contextStyles={styles.withTooltip}>
                    <div
                        className={cn(styles.permsControlContainer, styles.withHover)}
                        onClick={onClickControl}
                    >
                        <div className={styles.permsLabelContainer}>
                            <div className={styles.permsLabel}>{permsName}</div>
                        </div>

                        {(!isBsv || quest.is_draft) && (
                            <div className={styles.permsControl}>
                                <i className="fa fa-angle-down" />
                            </div>
                        )}
                    </div>
                </WithTooltip>
            ) : (
                <div className={styles.permsControlContainer}>
                    <div className={styles.permsLabelContainer}>
                        <div className={styles.permsLabel}>{permsName}</div>
                    </div>
                </div>
            )}
        </div>
    )
}
