import React, { useState, useCallback } from 'react'
import cn from 'classnames'
import { initials, isS3LinkExpired } from 'utils'
import { debounce } from 'lodash'
import api from 'api/api'
import styles from 'components/teams/team-settings.module.scss'

const failedUrls = new Set()

export default function TeamLogo(props) {
    const team = props.team
    const name = team.name
    const teamLogo = team.team_image?.url
    const imageStyles = teamLogo ? styles.imageStyles : null
    const [imageUrl, setImageUrl] = useState(teamLogo)

    const handleExpiredImgUrl = useCallback(
        debounce(
            async img => {
                try {
                    const imgUrl = img.src
                    if (failedUrls.has(imgUrl)) return

                    const questId = props.quest?.id
                    if (questId && isS3LinkExpired(imgUrl)) {
                        failedUrls.add(imgUrl)
                        const quest = await api.getQuest(questId, { break_cache: true })
                        setImageUrl(quest.team.team_image.url)
                    }
                } catch (error) {
                    console.error('Error handling expired image URL:', error)
                }
            },
            300,
            { leading: true },
        ),
        [],
    )

    return (
        <div
            className={cn(
                styles.teamLogoComp,
                imageStyles,
                props.contextStyles,
                props.borderStyles,
            )}
        >
            {teamLogo ? (
                <img src={imageUrl} onError={ev => handleExpiredImgUrl(ev.currentTarget)} />
            ) : (
                <div className={cn(styles.teamInitial, props.initialStyles)}>{initials(name)}</div>
            )}
        </div>
    )
}
